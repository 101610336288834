.inverse {
  color: var(--color-text-inverse);
}

.roundedButton {
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.sprintcard {
  border: none;
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow-elevation-mid);
}

.sprintcard.sprintcard:hover {
  box-shadow: var(--shadow-elevation-high);
}

.quickSetup {
  width: 60%;
  padding: 20px;
}

.centered {
  text-align: center;
}

.vertDivider {
  height: 80%;
}