.modal {
  width: 100%;
  max-width: 650px;
}

.right {
  padding-bottom: 20px;
}

.left {
  flex-basis: content;
  width: auto;
  min-width: 0;
}

.empty:not(:hover):not(:focus-within) {
  opacity: 0.7;
}

.activeTab {
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
}

.selected {
  border: 1px solid var(--color-primary);
}

.softFill {
  flex: 1 1 auto;
  width: 100%;
}

.scrollable {
  overflow-y: auto;
}

.textSection {
  padding: 8px 8px 10px;
}

.textbox.textbox > div  {
  padding: 4px;
  padding-bottom: 0;
}

.textbox:hover {
  background-color: transparent;
}