.saving {
  width: 90px;
}

.footerButton {
  border-radius: 0;
  height: 24px;
  padding: 0;
  font: var(--font-text-small);
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  text-align: center;
  text-wrap: none;
  white-space: nowrap;
  padding: 0 8px;
}

.footerButton * {
  line-height: 1em;
}