/* Nav container that has two columns */
.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
}

.outerNav {
  height: 100%;
  max-height: 100vh;
  overflow: none;
  width: 64px;
  border-right: var(--border-secondary);
  display: flex;
  row-gap: 4px;
  flex-direction: column;
  padding: 10px 0 6px;
  align-items: center;
  isolation: isolate; /* New stacking context */
}

.innerNav {
  height: 100%;
  /* Hack as it's pushing AppFooterBar offscreen */
  max-height: calc(100vh - 24px);
  overflow: none;
  width: 240px;
  max-width: 240px; 
  min-width: 240px; 
  display: flex;
  row-gap: 0;
  flex-direction: column;
  padding: 0;
}

.innerNav > * {
  padding-left: 20px;
  padding-right: 10px;
}

.innerNav > *:last-child {
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .innerNav {
    max-width: 210px;
  }
}

@media (max-width: 1200px) {
  .innerNav {
    max-width: 180px;
  }
}

.largeIcon {
  font-size: 18px;
  font-weight: bold;
  color: inherit;
  border-radius: 12px;
  line-height: 40px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-out, border-radius 0.2s var(--snappy-bezier), opacity 0.3s ease-in-out;
  box-shadow: var(--shadow-elevation-low);
  position: relative;
}

.largeIcon.subtle {
  /* Lighten by 20% */
  background: color-mix(in srgb, var(--color-text), var(--color-background) 85%);
}

/* Slack-like hover animation around icon (spaced a few pixels out and thicker border) */
.largeIcon.selected::before,
.largeIcon:hover::before {
  content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  box-sizing: border-box;
  border: 2px solid var(--color-border-secondary);
  border-radius: 16px;
}

.largeIcon:not(.selected)::before {
  z-index: -1;
}

.largeIcon:active {
  transform: scale(0.95);
}

.largeIcon.selected::before {
  border-color: var(--color-primary);
}

.largeIcon.person {
  border-radius: 100%;
}

.largeIcon.person.selected::before,
.largeIcon.person:hover::before {
  border-radius: 100%;
}

.pinIndicator {
  padding: 1px;
  border-radius: 100%;
}

.backMenu {
  margin-top: -6px;
}

.group + .group {
  margin-top: 26px;
}

.fit {
  width: 100%;
  max-width: 100%;
}

.menuItem.menuItem {
  color: var(--color-text-secondary);
}

.menuItem.archived.open {
  background-color: var(--color-subtle-primary-alt);
}

.menuItem.archived:hover {
  background-color: var(--color-subtle-primary-alt-hover);
}

.menuItem.archived:global(.active),
.menuItem.archived:active {
  background-color: var(--color-subtle-primary-alt-active);
}

.fill {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}

.labels {
  flex: 0 0 auto;
}


.onHoverTarget {
  position: absolute;
  right: 8px;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.2s ease;
}

.onHover:hover .onHoverTarget {
  opacity: 1;
  transform: translateX(0);
}

.divider {
  margin: 8px 0;
  background-color: var(--color-border);
}

.oneLine {
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primaryAction.primaryAction {
  border-radius: 100px;
  width: 50%;
}

.meetingButton,
.searchButton,
.newButton {
  text-align: center;
}

.newButton {
  border: none;
}

.newButton .icon {
  color: var(--color-text-inverse);
}

.searchButton {
  background-color: var(--color-subtle-active);
}

.meetingButton {
  border-radius: 100px;
  text-align: center;
  width: 100%;
  background-color: var(--color-subtle-danger);
}

.meetingButton .text {
  color: var(--color-text);
}

.clickable:hover {
  background-color: var(--color-subtle-hover);
  border-radius: var(--border-radius-small);
}

.clickable:not(:hover) .onHover {
  opacity: 0;
}

.clickable:hover > * {
  color: var(--color-text);
}

/*
* Hovering Nav
*/

.hoverContainer {
  position: relative;
  height: 100%;
  overflow: visible;
}

.overlay {
  position: absolute;
  top: 6px;
  left: 62px;
  bottom: 6px;
  transform: translateX(0);
  background: rgba(var(--color-rgb-background), 0.8);
  backdrop-filter: blur(5px);
  border-radius: var(--border-radius-medium);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: var(--shadow-elevation-high);
  opacity: 1;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.overlay:not(.hovering) {
  transform: translateX(-100%);
  opacity: 0;
}

.overlay .innerNav {
  max-width: 240px !important;
  width: 240px;
  padding-right: 10px;
}

.hoverContainer .overlay {
  z-index: 1;
}

.hoverContainer .outerNav {
  background-color: transparent;
  transition: background-color 0.05s ease;
  z-index: 2;
}

.hoverContainer.hovering .outerNav {
  background-color: var(--color-background);
}
